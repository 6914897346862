import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Container } from 'reactstrap';

// Lazy load components
const Header = lazy(() => import('../Component/Header'));
const Title = lazy(() => import('../Component/Title'));
const Footer2 = lazy(() => import('../Component/Footer2'));
const Loading = lazy(() => import('../Component/Loading'));

const PrivacyPolicy = () => {
    const [loading, setLoading] = useState(true);
    const title = "Privacy Policy";

    useEffect(() => {
        // Simulate loading process
        const timer = setTimeout(() => {
            setLoading(false);
        }, 10); // Loading timeout duration

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, []);

    if (loading) {
        return (
            <Suspense fallback={<div></div>}>
                <Loading />
            </Suspense>
        );
    }

    return (
        <div>
            <Suspense fallback={<div><Loading /></div>}>
                <Header />
                <div className='rounded-5' style={{ backgroundColor: "#F6F6F6" }}>
                    <Title title={title} />
                    <div>
                        <Container className='py-5 px-4 fs-5 BwGradual'>
                            <h1 className='BwGradualBold py-4'>Privacy Policy for LOL App</h1>
                            <p>Last updated: 19/10/2024</p>
                            <p className='BwGradual'>
                            LOL ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy outlines the types of personal information we collect, how we use and safeguard that information, and your rights as a user of our app. By using the LOL app, you agree to the practices described in this policy.
                        </p>
                            <p className='BwGradualBold py-3 m-0 fs-3 pt-4'>1. Information We Collect</p>
                            <p>We collect minimal personal information necessary for users to set up a profile and use the app.</p>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'><b className='BwGradualBold'>Username : </b>Users manually provide a unique username, which is used to identify their profile within the LOL app.</p>
                            </div>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'><b className='BwGradualBold'>Name : </b>Users manually provide their name, but this information is not shared publicly unless they choose to do so.</p>
                            </div>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'><b className='BwGradualBold'>Avatar : </b>Users manually upload an avatar or image for their profile. This is optional.
                                We do not collect any other personal information such as email addresses, phone numbers, or payment details.</p>
                            </div>
                            <p className='ps-4 pt-3'>Additionally, we collect anonymous data in the following ways.</p>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'><b className='BwGradualBold'>Location and Relationship Hints : </b>Users who create cards anonymously via a webview may voluntarily provide location and relationship hints. These hints are not linked to any identifiable information unless explicitly provided by the user.</p>
                            </div>




                            <p className='BwGradualBold py-3 m-0 fs-3 pt-4'>2. How We Use Your Information</p>
                            <p>We use the information you provide to:</p>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'>Create and manage your user profile.</p>
                            </div>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'>Facilitate anonymous card exchanges between you and others.</p>
                            </div>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'>Provide features and services within the LOL app.</p>
                            </div>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'>Offer enhanced features to Premium Members, including hints about who sent a card, relationship hints, and optional location information based on the sender’s consent.</p>
                            </div>
                            <p>We do <b className='BwGradualBold'>not</b> collect or use personal data from third parties, such as friends, contacts, or other individuals, without their knowledge or consent.</p>



                            
                            <p className='BwGradualBold py-3 m-0 fs-3 pt-4'>3. Sharing Your Information</p>
                            <p>We share your information only under these limited circumstances:</p>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'><b className='BwGradualBold'>With other users : </b>When you share a LOL card, others will see your username and avatar. Any additional information, such as hints about the sender (e.g., relationship or location), is only available if you're a Premium Member and the sender has given explicit consent to share that information.</p>
                            </div>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'><b className='BwGradualBold'>As required by law : </b>We may disclose your information if necessary to comply with legal obligations or protect the rights, safety, or property of LOL, its users, or others.</p>
                            </div>



                            <p className='BwGradualBold py-3 m-0 fs-3 pt-4'>4. Data Security</p>
                            <p>We take the security of your personal information seriously and use industry-standard measures to protect it. However, please note that no method of data transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.</p>


                            <p className='BwGradualBold py-3 m-0 fs-3 pt-4'>5. Safety Features</p>
                            <p>We prioritize user safety and provide tools to enhance your experience:</p>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'><b className='BwGradualBold'>Blocking and Pausing Links : </b>Users can block or pause a link if they receive unwanted or inappropriate LOL cards.</p>
                            </div>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'><b className='BwGradualBold'>Anonymity : </b>Users who respond to your LOL cards remain anonymous unless Premium Member features (such as hints) are enabled with consent.</p>
                            </div>



                            <p className='BwGradualBold py-3 m-0 fs-3 pt-4'>6. Premium Members</p>
                            <p>If you choose to subscribe to Premium Membership, you gain access to additional features:</p>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'><b className='BwGradualBold'>Hints about Card Senders : </b>You'll receive hints regarding the relationship and location (with the sender's permission) of those who send LOL cards.</p>
                            </div>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'><b className='BwGradualBold'>Early Access to Features : </b>Premium Members receive early access to new and experimental features.</p>
                            </div>
                            <p>Premium Membership does not require you to provide additional personal information.</p>




                            <p className='BwGradualBold py-3 m-0 fs-3 pt-4'>7. Children's Privacy</p>
                            <p>LOL is not intended for users under the age of 13, and we do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under the age of 13, we will take steps to delete that information.</p>



                            <p className='BwGradualBold py-3 m-0 fs-3 pt-4'>8. Your Rights</p>
                            <p>You have the right to:</p>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'>Access and update the personal information you provide in the LOL app.</p>
                            </div>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'>Request the deletion of your account and personal data from our systems.</p>
                            </div>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'>Block or pause any LOL link if you receive inappropriate content.</p>
                            </div>
                            <p>To exercise these rights, you can contact us at [Insert Contact Information].</p>


                            <p className='BwGradualBold py-3 m-0 fs-3 pt-4'>9. Changes to This Privacy Policy</p>
                            <p>We may update this Privacy Policy from time to time. When we do, we will revise the "Last updated" date at the top of this page. Your continued use of the app after any changes constitutes acceptance of the updated Privacy Policy.</p>



                            <p className='BwGradualBold py-3 m-0 fs-3 pt-4'>10. Contact Us</p>
                            <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'>Email : help@lolcards.link</p>
                            </div>
                            <div className='d-flex gap-3 ps-4'>
                                <p className='m-0'><FontAwesomeIcon icon={faCircle} style={{ fontSize: "8px" }} className='pb-1 pe-3' /></p>
                                <p className='m-0'>Address : 305,AR Mall Opp panvel point, Surat, Gujarat 394105, India</p>
                            </div>

                            
                        </Container>
                    </div>
                    <Footer2 />
                </div>
            </Suspense>
        </div>
    );
};

export default PrivacyPolicy;